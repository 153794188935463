.contact-container {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  background-color: #f5f5f5;
  background-image: url('../Images/background-img.webp');
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;
  background-blend-mode: multiply;
  align-items: center;
}
.contact-text-container{
  height: 10%;
}
.contact-items-container{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 2fr;
  align-content: first baseline;
  align-items: baseline;
  justify-items: center;
  height: 100%;
  width: 100%;
  gap: 2rem;
  padding: 3rem;
}

.contact-item-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
  align-items: center;
  height: 100%;
  width: 100%;
}
.map-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2rem; /* Ajusta el margen según sea necesario */
  width: 80%; /* Asegura que el contenedor ocupe todo el ancho disponible */
  height: 80%; /* Asegura que el contenedor ocupe todo el alto disponible */
}

.map-container iframe {
  width: 80%; /* Hace que el iframe ocupe todo el ancho del contenedor */
  height: 80%; /* Hace que el iframe ocupe todo el alto del contenedor */
}


.contact-items-container > :nth-child(1) {
  grid-column: 1/2;
}
.contact-items-container > :nth-child(2) {
  grid-column: 2/3;
}
.contact-items-container > :nth-child(3) {
  grid-column: 1/-1;
  grid-row: span 2;
}
.social-link {
  font-size: 3rem;
  margin: 2rem;
}

@media (min-width: 481px) and (max-width: 1024px) {
  .contact-container{
    height: max-content;
  }
}
@media (max-width: 750px) {
  .contact-items-container {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    padding: 1rem;
  }
  .contact-items-container > :nth-child(1),
  .contact-items-container > :nth-child(2),
  .contact-items-container > :nth-child(3) {
    grid-column: auto; 
    grid-row: auto; 
  }
}
@media (max-width: 480px) {
  .contact-container{
    height: max-content;
  }
  
}



 

