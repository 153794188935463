
.carousel .slide {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30rem; 
}

.carousel .slide img {
  max-width: 100%;
  max-height: 30rem;
  object-fit: contain;
}


.gallery-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1rem;
  background-color: #ebebeb;
  background-image: url("../Images/background-img.webp");
  background-repeat: repeat;
  background-size: cover;
  background-position: center;
}

.gallery-desc-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin-bottom: 2rem;
}


.carousel-root {
  max-width: 800px;
  margin: 0 auto;
}


.carousel .control-arrow {
  background-color: rgba(0, 0, 0, 0.5);
}


.carousel .thumbs-wrapper {
  margin-top: 20px;
}


.carousel .legend {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
}


@media (max-width: 768px) {
  .gallery-desc-container {
    margin-bottom: 1rem;
  }

  .carousel-root {
    max-width: 100%;
  }

  .carousel-container {
    padding: 0.5rem;
  }

  .carousel .control-arrow,
  .carousel .thumbs-wrapper {
    display: none;
  }
}
