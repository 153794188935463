.values-container {
  padding-top: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  padding: 3rem 5rem;
  justify-content: space-evenly;
}

.values-desc-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin: 1rem;
}

.values-items-container {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  align-items: baseline;
}


.value-item-container {
  flex: 1;
  min-width: 15rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.img-value-container {
  width: 10rem;
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 50%;
  background-color: var(--primary-palette--color2);
}

.text-container {
  padding: 2%;
}

.icon {
  color: white;
  height: 10rem;
  transition: transform 0.3s;
}

.value-item-container:hover .icon {
  transform: scale(1.4);
}
@media (min-width: 481px) and (max-width: 1024px) {
  .values-container{
    height: max-content;
  }
}
@media (max-width: 480px) {
  .values-container{
    height: max-content;
  }
}