.header {
  position: sticky;
  height: 5rem;
  width: 100%;
  top: 0;
  left: 0;
  background-color: var(--primary-palette--color1);
  z-index: 9999;
}

.nav-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 3rem ;
}

.nav__logo {
  height: 10em;
  width: auto;
  padding: 1.5rem 2rem;
}

.nav__logo img{
  height: 100%;
  width: auto;
  padding: 1rem;
}

.nav__toggle,
.nav__close {
  font-size: 2.5rem;
  color: #fff;
  cursor: pointer;
  margin-right:  2rem;
}


@media screen and (max-width: 1150px) {
  .nav-container {
    margin: 0;
  }
  .nav__menu {
    position: fixed;
    top: 0;
    right: -100%;
    background-color: #60d9d5a1;
    backdrop-filter: blur(16px);
    -webkit-backdrop-filter: blur(16px);
    height: 100%;
    padding: 6rem 3rem 0;
    transition: right 0.4s;
  }
}
@media screen and (max-width: 300px) {
  
  .nav__menu {
    padding: 4rem 1rem 2rem;
  }

  .nav__list {
    row-gap: 1rem;
  }

  .nav__link,
  .nav__cta {
    font-size: var(--smaller-font-size);
  }

  .nav__cta {
    padding: 8px 16px;
    font-size: 12px;
    margin-top: 1rem;
    width: auto;
    white-space: nowrap;
  }
}

.nav__list {
  display: flex;
  flex-direction: column;
  row-gap: 2.5rem;
  align-items: center;
}

.nav__link {
  color: var(--title-color);
  font-weight: var(--font-semi-bold);
  transition: color 0.4s;
}

.nav__link:hover {
  color: var(--first-color);
}

.nav__close {
  position: absolute;
  top: 1rem;
  right: 1.5rem;
}

.show-menu {
  right: 0;
}
.nav__listt {
  display: flex;
  flex-direction: row;
  column-gap: 2.5rem;
}

.nav__menu_mobile {
  position: fixed;
  top: 0;
  right: -100%;
  background-color: hsla(0, 0%, 6%, 0.2);
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
  width: 80%;
  height: 100%;
  padding: 6rem 3rem 0;
  transition: right 0.4s;
}
@media screen and (min-width: 1150px) {
  .nav {
    height: calc(var(--header-height) + 1rem);
  }
  .nav__toggle,
  .nav__close {
    display: none;
  }

  .nav__list {
    flex-direction: row;
    column-gap: 2.5rem;
  }
}