.footer-container {
  height: 5%;
  display: grid; 
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  justify-items: center;
  color: #fff;
  box-sizing: border-box;
}
footer{
  background-color: var(--secondary-palette--color-2);
}
footer a {
  color: var(--dark-palette--color3);
  text-decoration: none;
  margin: 1rem;
}

footer a:hover {
  text-decoration: underline;
}