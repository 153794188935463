#hero{
    display: grid;
    background-color: var(--primary-palette--color2);
    background-image: url("../Images/background-img.webp");
    background-repeat: repeat;
    background-size: cover;
    background-position: center;
    background-blend-mode: multiply;
    align-content: space-evenly;
    justify-items: center;
    text-align: center;
    padding: 35vh 0;
}


