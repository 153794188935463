.about-section-container {
  display: grid;
  grid-template-columns: 3fr 2.5fr; 
  grid-gap: 2rem; 
  justify-items: center;
  align-items: center;
  background-color: #f5f5f5;
  background-image: url("../Images/background-img.webp");
  background-repeat: repeat;
  background-size: cover;
  background-position: center;
  background-blend-mode: multiply;
  padding: 1rem 5rem;
  padding-top: 4rem;
  text-align: start;
}

@media (max-width: 768px) {
  .about-section-container {
    height: fit-content;
    grid-template-columns: 1fr; 
    grid-template-rows: 1fr 1fr; 
    grid-gap: 1rem;
    padding: 1rem;
  }

  .about-image-container {
    grid-row: 1;
    max-width: 100%; 
    height: auto; 
    border-radius: 1rem; 
    overflow: hidden; 
  }

  .about-image-container img {
    display: block;
    width: 100%;
    height: auto;
  }
}
.about-text-container{
  display: flex;
  width: 100%;
  flex-direction: column;
}

.about-image-container {
  max-width: 100%; 
  height: auto; 
  border-radius: 1rem; 
  overflow: hidden; 
}

.about-image-container img {
  display: block;
  width: 100%;
  height: auto;
}