* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family:"Roboto", sans-serif;


  /* Variables de colores */
  --primary-palette--color1 :#60d9d4;
  --primary-palette--color2 :#62dbd7;
  --primary-palette--color3 :#65d8d4;
  --primary-palette--color4 :#67dbd7;
  --secondary-palette--color1: #ebf2f2;
  --secondary-palette--color-2: #d0f2e7;
  --secondary-palette--color-3: #bcebdf;
  --secondary-palette--color-4: #ade0db;
  --secondary-palette--color-5: #d9dbdb;
  --dark-palette--color1 :#000000;
  --dark-palette--color2 :#292929;
  --dark-palette--color3 :#515151;
  --dark-palette--color4 :#7a7a7a;
  --dark-palette--color5 :#f0f0f0;
  --dark-palette--silver : #C0C0C0;
 
}

main {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    "header"
    "content";
  position: absolute;
  height: 100vh;
  width: 100%;
}
main header{
  grid-area: header;
  position: sticky;
  top: 0;
}
main .content{
  grid-area: content;
  overflow-y: auto;
  scrollbar-width: none;
}

main .content ::-webkit-scrollbar {
  display: none;
}
li{
  list-style:none
}

.content{
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
}
.primary-heading {
  font-size: clamp(1.8rem, 4vw, 3.5rem);
  font-weight: 700;
  color: var(--dark-palette--color5);
  text-align: center;
}
.primary-subheading {
  font-size: clamp(0.9rem, 2vw, 1.8rem);
  font-weight: 500;
  color: var(--dark-palette--color5);
  text-align: center;
}
.primary-text {
  font-size: clamp(0.9rem, 1.8vw, 1.3rem);
  font-weight: 400;
  color: var(--dark-palette--color5);
  margin: 1rem 0;
  text-align: center;
}
.secondary-heading {
  font-size: clamp(1.8rem, 4vw, 3.5rem);
  font-weight: 700;
  color: var(--dark-palette--color2);
  text-align: center;
}
.secondary-subheading {
  font-size: clamp(0.9rem, 2vw, 1.8rem);
  font-weight: 500;
  color: var(--dark-palette--color3);
  text-align: center;
}
.secondary-text {
  font-size: clamp(0.9rem, 1.8vw, 1.3rem);
  font-weight: 400;
  color: var(--dark-palette--color3);
  margin: 1rem 0;
  text-align: center;
}


.button-container{
  display: flex;
  justify-content: center;
  align-items: center;
}
.button {
  position: relative;
  color: var(--dark-palette--color5);
  border-radius: 1rem;
  text-shadow: 0 0 5px rgb(0, 0, 0);
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  transition: 0.5s;
  z-index: 1;
  margin:2rem;
}

.button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-bottom:var(--primary-palette--color5) 3px;
  z-index: -2;
  transform: scale(0);
  transition: 0.5s;
}
.button::after {
  content: '';
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 100%;
  height: 4px; 
  background-color: var(--primary-palette--color2) ; 
  text-shadow: 1px 1px 15px rgb(0, 255, 64);
  z-index: -1;
  transition: 0.5s;
  opacity: 0; 
}

.button:hover::after {
  opacity: 1; 
  text-shadow: 1px 1px 15px rgb(0, 255, 64);
}
.button:hover{
  text-shadow: 0 0 20px rgb(0, 255, 64); 
}

.button-main {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: var(--primary-palette--color4);
  font-family: "Montserrat", sans-serif;
  box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  cursor: pointer;
  border: none;
}

.button-main:after {
  content: " ";
  width: 0%;
  height: 100%;
  background: var(--primary-palette--color3);
  position: absolute;
  transition: all 0.4s ease-in-out;
  right: 0;
}

.button-main:hover::after {
  right: auto;
  left: 0;
  width: 100%;
}

.button-main  span {
  text-align: center;
  text-decoration: none;
  width: 100%;
  padding: 18px 25px;
  color: #fff;
  font-size: 1.125em;
  font-weight: 700;
  letter-spacing: 0.3em;
  z-index: 1;
  transition: all 0.3s ease-in-out;
}

.button-main:hover span {
  color: #183153;
  animation: scaleUp 0.3s ease-in-out;
}

@keyframes scaleUp {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.95);
  }

  100% {
    transform: scale(1);
  }
}