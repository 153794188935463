.services-container {
  padding-top: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f5f5f5;
  padding: 3rem 5rem;
  justify-content: space-evenly;
  text-align: center;
  
}

.services-desc-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin: 1rem;
}

.services-items-container {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  align-items: baseline;
  margin-bottom: 5rem;
}

.service-item-container {
  flex: 1;
  min-width: 15rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.img-service-container {
  width: 15vh;
  height: 15vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.text-container {
  padding: 2%;
}

.img-service-container .icon {
  color: var(--primary-palette--color2);
  height: 10rem;
  transition: transform 0.3s;
}

.service-item-container:hover .icon {
  transform: scale(1.4);
}

@media (max-width: 768px) {
  .services-items-container {
    grid-template-columns: repeat(2, 1fr);
  }

  .img-service-container {
    width: 10vh;
    height: 10vh;
  }

  .text-container {
    padding: 1%;
  }

  .img-service-container .icon {
    height: 7rem;
  }
}
@media (min-width: 481px) and (max-width: 1024px) {
  .services-container{
    height: max-content;
  }
}
@media (max-width: 480px) {
  .services-container{
    height: max-content;
  }
}